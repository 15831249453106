var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('Logo'),_c('b-card-title',{staticClass:"text-center mb-1"},[_vm._v(" "+_vm._s(_vm.$t('WELCOME').replace('Olympus', _vm.serverName))+" ")]),_c('b-alert',{attrs:{"variant":"info","show":_vm.serverName === 'Olympus: Odyssey'}},[_c('div',{staticClass:"alert-body text-justify"},[_c('span',[_vm._v(" Olympus and Odyssey share the same credentials. "),_c('br'),_vm._v(" If you already have an Olympus account, please "),_c('b-link',{attrs:{"to":"/login"}},[_c('span',[_vm._v(_vm._s(_vm.$t('LOGIN').toLowerCase()))])]),_vm._v(" instead. ")],1)])]),(_vm.success)?_c('b-card-text',{staticClass:"text-center text-success mb-2"},[_vm._v(" "+_vm._s(_vm.$t('ACCOUNT_CREATED'))+" ")]):_vm._e(),(_vm.error)?_c('b-card-text',{staticClass:"text-center text-danger mb-2"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('ValidationObserver',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validateForm($event)}}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('ValidationProvider',{attrs:{"name":"Username","rules":"required|min:5|max:26"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null,"name":"register-username","placeholder":_vm.$t('USERNAME'),"disabled":_vm.isRegistrationDisabled},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('EMAIL.TITLE'),"label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":_vm.$t('EMAIL.TITLE'),"disabled":_vm.isRegistrationDisabled},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('PASSWORD.TITLE'),"label-for":"password"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('PASSWORD.TITLE'),"rules":"required|min:6","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············","disabled":_vm.isRegistrationDisabled},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('PASSWORD.CONFIRM'),"label-for":"c-password"}},[_c('ValidationProvider',{attrs:{"name":"Password Confirm","rules":"required|min:6|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"id":"c-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"placeholder":"············","disabled":_vm.isRegistrationDisabled},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":"TOS agreement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.TOSCheckbox),callback:function ($$v) {_vm.TOSCheckbox=$$v},expression:"TOSCheckbox"}},[_c('i18n',{attrs:{"path":"AGREE_TO_TOS_AND_PP","tag":"span"},scopedSlots:_vm._u([{key:"tos",fn:function(){return [_c('a',{attrs:{"href":"/terms-of-service","target":"blank"}},[_vm._v(_vm._s(_vm.$t('TERMS_OF_SERVICE')))])]},proxy:true},{key:"pp",fn:function(){return [_c('a',{attrs:{"href":"/privacy-policy","target":"blank"}},[_vm._v(_vm._s(_vm.$t('PRIVACY_POLICY')))])]},proxy:true}],null,true)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"text-center"},[_c('Recaptcha',{on:{"getRecaptcha":_vm.setRecaptcha}})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('REGISTER'))+" ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('ACCOUNT_ALREADY_CREATED'))+" ")]),_c('b-link',{attrs:{"to":"/login"}},[_c('span',[_vm._v(_vm._s(_vm.$t('LOGIN')))])])],1),_c('BackLinks',{staticClass:"text-center",attrs:{"communityOnly":""}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }