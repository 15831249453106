




























































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { FormHelper } from '@/mixins'
import { Auth } from '@/api'
import { themeConfig } from '@/config/themeConfig'

@Component({
  components: {
    BackLinks: () => import('@/components/Backlinks.vue'),
    Logo: () => import('@/components/Logo.vue'),
    Recaptcha: () => import('@/components/Recaptcha.vue')
  }
})
export default class Register extends Mixins(FormHelper) {
  serverName = themeConfig.app.name
  regEmail = ''
  username = ''
  password = ''
  passwordConfirmation = ''
  TOSCheckbox = ''
  passwordFieldType = 'password'
  success = false
  error = ''
  isRegistrationDisabled = false

  $refs!: {
    registerForm: HTMLFormElement
  }

  async mounted() {
    document.addEventListener('keydown', e => this.handleEnterKey(e.key, this.validateForm))
  }

  async beforeDestroy() {
    document.removeEventListener('keydown', e => this.handleEnterKey(e.key, this.validateForm))
  }

  async validateForm() {
    const { username, regEmail, password, passwordConfirmation, recaptcha } = this

    const isFormValid = await this.$refs.registerForm.validate()

    if (!isFormValid) return

    const errors = await Auth.register({
      username,
      email: regEmail,
      password,
      passwordConfirmation,
      recaptcha
    })

    if (errors.detail) {
      this.error = errors.detail
      this.$root.$emit('resetCaptcha')
    } else {
      this.success = true
    }
  }
}
